import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    updloadMedia(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/media/upload',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRedirectLink(ctx, { id, media }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/media/${id}`, media)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMedia(ctx, {
      id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/media/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
