/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    statusOrders: [
      {
        label: 'New',
        value: 'NEW',
      },
      // {
      //   label: 'On payment',
      //   value: 'ONPAYMENT',
      // },
      {
        label: 'Check out',
        value: 'CHECKOUT',
      },
      {
        label: 'Paid',
        value: 'PAID',
      },
      {
        label: 'Failed',
        value: 'FAILED',
      },
      {
        label: 'Shipped',
        value: 'SHIPPED',
      },
      {
        label: 'Delivered',
        value: 'DELIVERED',
      },
      {
        label: 'Returned',
        value: 'RETURNED',
      },
      {
        label: 'Complete',
        value: 'COMPLETE',
      },
      {
        label: 'Canceled',
        value: 'CANCELED',
      },
    ],
    paymentStatus: [
      {
        label: 'Created',
        value: 'payment_intent.created',
      },
      {
        label: 'Succeeded',
        value: 'payment_intent.succeeded',
      },
      {
        label: 'Failed',
        value: 'payment_intent.payment_failed',
      },
    ],
    mapStatusOrders: [
      {
        label: 'New',
        value: 0,
      },
      {
        label: 'On payment',
        value: 1,
      },
      {
        label: 'Check out',
        value: 2,
      },
      {
        label: 'Paid',
        value: 3,
      },
      {
        label: 'Failed',
        value: 4,
      },
      {
        label: 'Shipped',
        value: 5,
      },
      {
        label: 'Delivered',
        value: 6,
      },
      {
        label: 'Returned',
        value: 7,
      },
      {
        label: 'Complete',
        value: 8,
      },
      {
        label: 'Canceled',
        value: 9,
      },
    ],
  },
  getters: {
    paymentStatus(state) {
      return state.paymentStatus
    },
    statusOrders(state) {
      return state.statusOrders
    },
    mapStatusOrders(state) {
      return state.mapStatusOrders
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products
    },
    setProductTags(state, productTags) {
      state.productTags = productTags.map(x => ({
        label: x.name,
        value: x.id,
      }))
    },
    setProductCategory(state, productCategory) {
      state.productCategory = productCategory.map(x => ({
        label: x.name,
        value: x.id,
      }))
    },
  },
  actions: {
    fetchOrders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/order', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/order/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payment', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
