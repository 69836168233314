/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    productBussiess: [],
  },
  getters: {
    productBussiess(state) {
      return state.productBussiess
    },
  },
  mutations: {
    setBussiness(state, productBussiess) {
      state.productBussiess = productBussiess.map(x => ({
        label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
        value: x.id,
      }))
    },
  },
  actions: {
    fetchBussiness(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/product-bussiness-challenge', {
            params: queryParams || null,
          })
          .then(response => {
            ctx.commit('setBussiness', response.body)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchBussinessList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/product-bussiness-challenge', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addBussiness(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product-bussiness-challenge', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBussiness(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/product-bussiness-challenge/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBussiness(ctx, { id, bussiness }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/product-bussiness-challenge/${id}`, bussiness)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
