// eslint-disable-next-line import/no-cycle
import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    saleType: [{
      label: 'Store wide discount',
      value: 'batch',
    },
    {
      label: 'Quantity range based tiered pricing percentage discount',
      value: 'bulk',
    },
    // {
    //   label: 'Order total based discount',
    //   value: 'coupon',
    // },
    ],
  },
  getters: {
    saleType: state => state.saleType,
  },
  mutations: {},
  actions: {
    fetchSales(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/sale', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSale(ctx, {
      id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/sale/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSale(ctx, saleData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/sale', saleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSale(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/sale/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSale(ctx, { id, sale }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/sale/${id}`, sale)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCoupons(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/coupon', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCoupon(ctx, couponData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/coupon', couponData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCoupon(ctx, {
      id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/coupon/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCoupon(ctx, { id, coupon }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/coupon/${id}`, coupon)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
    },
    deleteCoupon(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/coupon/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      });
    },
  },
}
