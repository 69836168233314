/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    products: [],
    productType: [
      {
        label: 'High touch',
        value: 'high_touch',
      },
      {
        label: 'Low touch',
        value: 'low_touch',
      },
      {
        label: 'Public low touch',
        value: 'public',
      },
    ],
    productTags: [],
    productStatus: [
      {
        label: 'Draft',
        value: 'draft',
      },
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Approved',
        value: 'approved',
      },
      {
        label: 'Reject',
        value: 'reject',
      },
    ],
    productCategory: [],
    productEdit: {},
  },
  getters: {
    products(state) {
      return state.products
    },
    productType(state) {
      return state.productType
    },
    productTags(state) {
      return state.productTags
    },
    productStatus(state) {
      return state.productStatus
    },
    productCategory(state) {
      return state.productCategory
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products
    },
    setProductTags(state, productTags) {
      state.productTags = productTags.map(x => ({
        label: x.name,
        value: x.id,
      }))
    },
    setProductCategory(state, productCategory) {
      state.productCategory = productCategory.map(x => ({
        label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
        value: x.id,
      }))
    },
  },
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/product', {
            params: queryParams,
          })
          .then(response => {
            ctx.commit('setProducts', response.body.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/product/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProductOnEdit(ctx, { id, product }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/product/on-edit/${id}`, product)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    confirmOnEdit(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/product/confirm-edit/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product', productData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, { id, product }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/product/${id}`, product)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStatusProduct(ctx, { id, confirm }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/product/update-status/${id}`, { confirm })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/product/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTags(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tag')
          .then(response => {
            ctx.commit('setProductTags', response.body)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTagsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tag', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/category', {
            params: queryParams || null,
          })
          .then(response => {
            ctx.commit('setProductCategory', response.body)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCategoriesList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/category', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addCategories(ctx, categoryData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/category', categoryData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategories(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/category/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategories(ctx, { id, category }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/category/${id}`, category)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTags(ctx, tagsData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tag', tagsData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTags(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/tag/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTags(ctx, { id, tag }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/tag/${id}`, tag)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
