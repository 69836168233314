import {
  authService
} from "@/auth";
import store from "@/store";
export default function ({
  to,
  from,
  next
}) {
  console.log(authService.getUser())
  console.log('auth sso');
  store.dispatch('authenticate/initAuth').then(() => next({ name: "home" }))

  // authService.getUser().then((res) => {
  //   store.dispatch('authenticate/refreshAuthenticateUserSso', res).then(() => next())

    // if (res) {
    //   store.dispatch('authenticate/refreshAuthenticateUserSso', res).then(() => next())
    // } else
    //   next();
  // }).catch(err => {
  //   next()
  // });
  // }
  // Inform the authentication of the login redirect. Afterwards we send the user to the main page
}