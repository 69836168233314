/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authenticate from './authenticate'
import user from './user'
import sale from './sale'
import product from './products'
import banner from './banner'
import categories from './categories'
import vendors from './vendors'
import media from './media'
// eslint-disable-next-line import/no-cycle
import language from './language'
import regionConfig from './regionConfig'
import mailchimp from './mailchimp'
import order from './order'
import report from './report'
import bussiness from './bussiness'
import role from './role'
import spotlight from './spotlight'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authenticate,
    language,
    user,
    sale,
    product,
    banner,
    categories,
    vendors,
    media,
    regionConfig,
    mailchimp,
    order,
    report,
    bussiness,
    role,
    spotlight,
  },
  strict: true,
})
