/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import * as enpointConfig from '@/utils/endpoints'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/category/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategories(ctx, category) {
      return new Promise((resolve, reject) => {
        axios
          .post('/category', category)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, { id, category }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/category/${id}`, category)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
