import store from '@/store/index'

export default function ({ to, from, next }) {
  store.dispatch('authenticate/initAuth')
  // if (!store.state.authenticate.token ) {
  //   store.dispatch('authenticate/initAuth')
    // if (!store.state.authenticate.token) {
    //   return next({ name: 'login' })
    // }
  // }
  return next()
}
