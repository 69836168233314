import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    roles: [],
  },
  getters: {},
  mutations: {
    setRoles(state, roles) {
      state.roles = roles
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/roles', {
            params: queryParams,
          })
          .then(response => {
            ctx.commit('setRoles', response.body.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchPermissions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/permissions', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, {
      id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, {
      id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRole(ctx, roleData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/roles', roleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRole(ctx, { id, role }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/roles/${id}`, role)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, {
      id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/roles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { id, user }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/users/${id}`, user)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetPass(ctx, userIds) {
      return new Promise((resolve, reject) => {
        axios
          .post('users/reset-password', {
            userIds,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetAllPass(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .post('users/reset-password-all')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    restoreUser(ctx, {
      id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/users/${id}/restore`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deactiveUser(ctx, {
      id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/users/${id}/inactive`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
