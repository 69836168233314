import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueAutosuggest from "vue-autosuggest";
Vue.use(VueAutosuggest);
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import "vue-select/dist/vue-select.css";
import auth from './auth'
// eslint-disable-next-line import/no-cycle
import router from './router'
// eslint-disable-next-line import/no-cycle
import store from './store'
import i18n from './language/i18n'
import acl from './acl'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/axios2'
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies';

Vue.use(auth)
// BSV Plugin Registration
Vue.use(acl)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueMeta)
Vue.use(VueCookies);

// Composition API
Vue.use(VueCompositionAPI)

// Vue.$cookies.config('7d', '', '', true, 'Strict');

// Install the authentication pluginvue-lazyload

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// disable console.log in production
if (!process.env.VUE_APP_DEVELOPMENT_MODE) {
  console.log = () => {};
}

const app = new Vue({
  acl,
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')

store.$app = app
export default app
