// eslint-disable-next-line import/no-cycle
import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSummary(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/app/summary-report', {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsersRegister(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/app/register-report?startTime=2022-01-02%2017%3A07%3A42&endTime=2023-02-21%2017%3A07%3A42', {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
  
}
