// eslint-disable-next-line import/no-cycle
import app from '../../main'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
    setLang(state, payload) {
      app.$i18n.locale = payload
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit('setLang', payload)
    },
  },
}
