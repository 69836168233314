/* eslint-disable import/no-cycle */
import { createAcl, defineAclRules } from 'vue-simple-acl'
import store from '@/store/index'
import * as config from '@/utils/config'
import router from '../router'

const user = () => {
  const common = true
  const permissions = []
  if (store.state.authenticate.permissions.length > 0) {
    store.state.authenticate.permissions.forEach(element => {
      if (!permissions.includes(element)) {
        permissions.push(element)
      }
    })
  }
  return {
    common,
    permissions,
  }
}

const rules = () => defineAclRules(setRule => {
  setRule('common', user => user().common)

  setRule('ur_read', user => user().permissions && user().permissions.includes('ur_read'))
  setRule('ur_manage', user => user().permissions && user().permissions.includes('ur_manage'))
  setRule('ur_create', user => user().permissions && user().permissions.includes('ur_create'))
  setRule('ur_update', user => user().permissions && user().permissions.includes('ur_update'))
  setRule('ur_delete', user => user().permissions && user().permissions.includes('ur_delete'))
  setRule('ur_deactivate', user => user().permissions && user().permissions.includes('ur_deactivate'))

  setRule('per_read', user => user().permissions && user().permissions.includes('per_read'))

  setRule('role_read', user => user().permissions && user().permissions.includes('role_read'))
  setRule('role_create', user => user().permissions && user().permissions.includes('role_create'))
  setRule('role_update', user => user().permissions && user().permissions.includes('role_update'))
  setRule('role_delete', user => user().permissions && user().permissions.includes('role_delete'))
  setRule('role_manage', user => user().permissions && user().permissions.includes('role_manage'))

  setRule('sale_read', user => user().permissions && user().permissions.includes('sale_read'))
  setRule('sale_create', user => user().permissions && user().permissions.includes('sale_create'))
  setRule('sale_update', user => user().permissions && user().permissions.includes('sale_update'))
  setRule('sale_delete', user => user().permissions && user().permissions.includes('sale_delete'))
  setRule('sale_manage', user => user().permissions && user().permissions.includes('sale_manage'))
  setRule('sale_deactivate', user => user().permissions && user().permissions.includes('sale_deactivate'))

  setRule('order_manage', user => user().permissions && user().permissions.includes('order_manage'))
  setRule('order_create', user => user().permissions && user().permissions.includes('order_create'))
  setRule('order_read', user => user().permissions && user().permissions.includes('order_read'))
  setRule('order_update', user => user().permissions && user().permissions.includes('order_update'))
  setRule('order_delete', user => user().permissions && user().permissions.includes('order_delete'))

  setRule('category_manage', user => user().permissions && user().permissions.includes('category_manage'))
  setRule('category_create', user => user().permissions && user().permissions.includes('category_create'))
  setRule('category_read', user => user().permissions && user().permissions.includes('category_create'))
  setRule('category_update', user => user().permissions && user().permissions.includes('category_update'))
  setRule('category_delete', user => user().permissions && user().permissions.includes('category_delete'))

  setRule('mail_manage', user => user().permissions && user().permissions.includes('mail_manage'))
  setRule('mail_create', user => user().permissions && user().permissions.includes('mail_create'))
  setRule('mail_read', user => user().permissions && user().permissions.includes('mail_read'))
  setRule('mail_update', user => user().permissions && user().permissions.includes('mail_update'))
  setRule('mail_delete', user => user().permissions && user().permissions.includes('mail_delete'))

  setRule('media_manage', user => user().permissions && user().permissions.includes('media_manage'))
  setRule('media_create', user => user().permissions && user().permissions.includes('media_create'))
  setRule('media_read', user => user().permissions && user().permissions.includes('media_read'))
  setRule('media_update', user => user().permissions && user().permissions.includes('media_update'))
  setRule('media_delete', user => user().permissions && user().permissions.includes('media_delete'))

  setRule('product_manage', user => user().permissions && user().permissions.includes('product_manage'))
  setRule('product_create', user => user().permissions && user().permissions.includes('product_create'))
  setRule('product_read', user => user().permissions && user().permissions.includes('product_read'))
  setRule('product_update', user => user().permissions && user().permissions.includes('product_update'))
  setRule('product_delete', user => user().permissions && user().permissions.includes('product_delete'))
  setRule('product_approve', user => user().permissions && user().permissions.includes('product_approve'))
  setRule('product_deactivate', user => user().permissions && user().permissions.includes('product_deactivate'))


  setRule('region_manage', user => user().permissions && user().permissions.includes('region_manage'))
  setRule('region_create', user => user().permissions && user().permissions.includes('region_create'))
  setRule('region_read', user => user().permissions && user().permissions.includes('region_read'))
  setRule('region_update', user => user().permissions && user().permissions.includes('region_update'))
  setRule('region_delete', user => user().permissions && user().permissions.includes('region_delete'))

  setRule('tag_manage', user => user().permissions && user().permissions.includes('tag_manage'))
  setRule('tag_create', user => user().permissions && user().permissions.includes('tag_create'))
  setRule('tag_read', user => user().permissions && user().permissions.includes('tag_read'))
  setRule('tag_update', user => user().permissions && user().permissions.includes('tag_update'))
  setRule('tag_delete', user => user().permissions && user().permissions.includes('tag_delete'))

  setRule('vendor_manage', user => user().permissions && user().permissions.includes('vendor_manage'))
  setRule('vendor_create', user => user().permissions && user().permissions.includes('vendor_create'))
  setRule('vendor_read', user => user().permissions && user().permissions.includes('vendor_read'))
  setRule('vendor_delete', user => user().permissions && user().permissions.includes('vendor_delete'))
  setRule('vendor_update', user => user().permissions && user().permissions.includes('vendor_update'))

  setRule('pm_read', user => user().permissions && user().permissions.includes('pm_read'))

  setRule('thirdparty_read', user => user().permissions && user().permissions.includes('thirdparty_read'))

  setRule('tracking_read', user => user().permissions && user().permissions.includes('tracking_read'))

  setRule('report_read', user => user().permissions && user().permissions.includes('report_read'))

  setRule('coupon_create', user => user().permissions && user().permissions.includes('coupon_create'))
  setRule('coupon_read', user => user().permissions && user().permissions.includes('coupon_read'))
  setRule('coupon_update', user => user().permissions && user().permissions.includes('coupon_update'))
  setRule('coupon_delete', user => user().permissions && user().permissions.includes('coupon_delete'))

  setRule('cart_create', user => user().permissions && user().permissions.includes('cart_create'))
  setRule('cart_read', user => user().permissions && user().permissions.includes('cart_read'))
  setRule('cart_update', user => user().permissions && user().permissions.includes('cart_update'))
  setRule('cart_delete', user => user().permissions && user().permissions.includes('cart_delete'))

  setRule('productrole_create', user => user().permissions && user().permissions.includes('productrole_create'))
  setRule('productrole_read', user => user().permissions && user().permissions.includes('productrole_read'))
  setRule('productrole_update', user => user().permissions && user().permissions.includes('productrole_update'))
  setRule('productrole_delete', user => user().permissions && user().permissions.includes('productrole_delete'))

  setRule('create_spotlight', user => user().permissions && user().permissions.includes('create_spotlight'))
  setRule('read_spotlight', user => user().permissions && user().permissions.includes('read_spotlight'))
  setRule('update_spotlight', user => user().permissions && user().permissions.includes('update_spotlight'))
  setRule('delete_spotlight', user => user().permissions && user().permissions.includes('delete_spotlight'))

  // Frontend helper
})
const simpleAcl = createAcl({
  user,
  rules,
  router,
})
export default simpleAcl
