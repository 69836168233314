// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import {
  authService,
} from '@/auth'
import * as config from '../../utils/config'
import Vue from 'vue';
import Cookies from 'js-cookie';


function clearCookie(name, domain, path) {
  var domain = domain || document.domain
  var path = path || '/'
  document.cookie = `${name}=; expires=${+new Date()}; domain=${domain}; path=${path}`
}
export default {
  namespaced: true,
  state: {
    token: null,
    permissions: [],
    userLogin: {},
    regionsAcept: '',
    regions: '',
  },
  getters: {
    isAuthenticated(state) {
      return state.token != null
    },
    userLogin(state) {
      return state.userLogin
    },
    regions(state) {
      return state.regions
    },
    regionsAcept(state) {
      return state.regionsAcept
    },

  },
  mutations: {
    clearToken(state) {
      state.token = null
    },
    setRegionsAcept(state, regionsAcept) {
      state.regionsAcept = regionsAcept
    },
    setRegions(state, regions) {
      state.regions = regions
    },
    setUserLogin(state, userLogin) {
      state.userLogin = userLogin
    },
    setToken(state, token) {
      state.token = token
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
    clearPermissions(state) {
      state.permissions = []
    },
  },
  actions: {
    authenticateUserLocal(ctx, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/admin-login', {
            email: credentials.email,
            password: credentials.password,
          },
         )
          .then(result => {
            if (result.request.status === 200) {
              // localStorage.setItem(config.TOKEN_KEY, result.body.token.accessToken)
              // localStorage.setItem(config.TOKEN_EXPIRATION_KEY, new Date().getTime() + result.body.token.expiresIn * 1000)
              // TODO:
              localStorage.setItem(config.USER, JSON.stringify(result.body.user))

              // Vue.$cookies.set(config.TOKEN_KEY,result.body.token.accessToken, '7d', null, null, true);
              // Vue.$cookies.set(config.TOKEN_EXPIRATION_KEY, new Date().getTime() + result.body.token.expiresIn * 1000);


              ctx.commit('setToken', result.body.token.accessToken)
              ctx.commit('setUserLogin', result.body.user)
              ctx.dispatch('mePermissions')
              // ctx.dispatch('setLogoutTimer', result.body.token.expiresIn * 1000)
            }
            resolve({
              success: true,
            })
          })
          .catch(error => {
            reject(error.message)
          })
      })
    },
    authenticateUserSso(ctx) {
      console.log('login successfully!!!');
      return new Promise((resolve, reject) => {
        ///
        // authService.getUser().then(result => {
          // console.log("getUser", result)
          // GET profile and token
          // if (result && result.profile) {
            // SET token to local storage
            // localStorage.setItem(config.TOKEN_KEY, result.access_token)
            // localStorage.setItem(config.TOKEN_EXPIRATION_KEY, new Date().getTime() + 3600 * 1000)

            // Vue.$cookies.set(config.TOKEN_KEY,result.body.token.accessToken, '7d', null, null, true);
            // Vue.$cookies.set(config.TOKEN_EXPIRATION_KEY, new Date().getTime() + 3600 * 1000);

            // TODO
            // localStorage.setItem(config.USER, JSON.stringify(result.profile))
            // Set user profile to store and permission

            // ctx.commit('setToken', result.access_token)
            ctx.commit('setUserLogin', result.profile)

            // ctx.dispatch('mePermissions')
            // ctx.dispatch('setLogoutTimer', 3600 * 1000)
          // }
        //   resolve({
        //     success: true,
        //   })
        // })

        // localStorage.setItem(config.USER, JSON.stringify(result.profile))
        // Set user profile to store and permission

        ctx.commit('setToken', result.access_token)
        ctx.commit('setUserLogin', result.profile)

        ctx.dispatch('mePermissions')
        ///
      })
    },
    logoutLocal(ctx) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/logout', {})
        resolve({success: true})
      })

    },
    refreshAuthenticateUserSso(ctx, payload) {
      return new Promise((resolve, reject) => {
        // if (payload && payload.profile) {
          // SET token to local storage
          // localStorage.setItem(config.TOKEN_KEY, payload.access_token)
          // localStorage.setItem(config.TOKEN_EXPIRATION_KEY, new Date().getTime() + 3600 * 1000)

          // Vue.$cookies.set(config.TOKEN_KEY,result.body.token.accessToken, '7d', null, null, true);
          // Vue.$cookies.set(config.TOKEN_EXPIRATION_KEY, new Date().getTime() + 3600 * 1000);

          // TODO
          localStorage.setItem(config.USER, JSON.stringify(payload.profile))
          // Set user profile to store and permission

          ctx.commit('setToken', payload.access_token)
          ctx.commit('setUserLogin', payload.profile)

          ctx.dispatch('mePermissions')
          // ctx.dispatch('setLogoutTimer', 3600 * 1000)
        // }
        resolve({
          success: true,
        })
        ///
      })
    },

    fetchRole(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    me(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/me')
          .then(response => {
            ctx.commit('setUserLogin', response.body)
            localStorage.setItem(config.USER, JSON.stringify(response.body))
            resolve(response.body)
          })
          .catch(error => reject(error))
      })
    },

    mePermissions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/my-permission')
          .then(response => {
            if (response.body.permissions.length === 0 && response.body.regions.length === 0) { return ctx.dispatch('logout') }
            const regionAccept = localStorage.getItem(config.REGION_ACEPT)
            ctx.commit('setPermissions', response.body.permissions.map(element => element.code))
            if (regionAccept) { ctx.commit('setRegionsAcept', regionAccept) } else {
              localStorage.setItem(config.REGION_ACEPT, response.body.regions.map(element => element.region.code).join(','))
              ctx.commit('setRegionsAcept', response.body.regions.map(element => element.region.code).join(','))
            }
            ctx.commit('setRegions', response.body.regions.map(element => element.region.code).join(','))
            localStorage.setItem(config.REGION, response.body.regions.map(element => element.region.code).join(','))
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    initAuth(ctx) {
      // const token = Vue.$cookies.get(config.TOKEN_KEY)
      // const tokenExpiration = Vue.$cookies.get(config.TOKEN_EXPIRATION_KEY)
      const region = localStorage.getItem(config.REGION)
      const regionAccept = localStorage.getItem(config.REGION_ACEPT)

      // if (!token || !tokenExpiration || !region || !regionAccept) { return ctx.dispatch('logout') }
      // if (!region || !regionAccept) { return ctx.dispatch('logout') }

      // if (new Date().getTime() > tokenExpiration || !token) { return ctx.dispatch('logout') }
      // console.log(Cookies.get())
      // ctx.commit('setToken', token)

      ctx.dispatch('me')
      ctx.dispatch('mePermissions')
      // ctx.dispatch('setLogoutTimer', tokenExpiration - new Date().getTime())
      return true
    },
    setLogoutTimer(ctx, duration) {
      setTimeout(() => {
        ctx.commit('clearToken')
      }, duration)
    },
    logout(ctx) {
      authService.logout()
      ctx.commit('clearToken')
      ctx.commit('clearPermissions')
      sessionStorage.clear()
      clearCookie('pwcGlobalSSID_stage', '.pwc.com')
      localStorage.removeItem(config.TOKEN_KEY)
      localStorage.removeItem(config.TOKEN_EXPIRATION_KEY)

      // Vue.$cookies.remove(config.TOKEN_KEY);
      // Vue.$cookies.remove(config.TOKEN_EXPIRATION_KEY);

      localStorage.removeItem(config.REGION)
      localStorage.removeItem(config.REGION_ACEPT)
      localStorage.removeItem(config.USER)
      sessionStorage.removeItem(config.IS_LOGIN_SSO)
    },
  },
}
