import { $themeBreakpoints } from '@themeConfig'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    offeringTypes: [
      {
        label: 'Asset',
        value: 'asset',
      },
      {
        label: 'Elearn',
        value: 'elearn',
      },
      {
        label: 'Event',
        value: 'event',
      },
      {
        label: 'Publication',
        value: 'publication',
      },
    ],
    regionOptions: [
      {
        label: 'VN(Vietnam)',
        value: 'vn',
        language: 'vi',
      },
      {
        label: 'MY(Malaysia)',
        value: 'my',
        language: 'ms',
      },
      {
        label: 'ID(Indonesia)',
        value: 'id',
        language: 'id',
      },
      {
        label: 'SG(Singapore)',
        value: 'sg',
        language: 'en',
      },
      {
        label: 'TH(Thailand)',
        value: 'th',
        language: 'th',
      },
      {
        label: 'KR(South Korea)',
        value: 'kr',
        language: 'kr',
      },
      {
        label: 'JP(Japan)',
        value: 'jp',
        language: 'ja',
      },
    ],
    languageOptions: [
      {
        label: 'English',
        value: 'en',
        region: 'my'
      },
      {
        label: 'Malay',
        value: 'ms',
        region: 'my'
      },
      {
        label: 'Vietnamese',
        value: 'vi',
        region:'vn'
      },
      {
        label: 'Chinese',
        value: 'zh',
        region:'sg'
      },
      {
        label: 'Indonesian',
        value: 'id',
        region: 'id',
      },
      {
        label: 'Thai',
        value: 'th',
        region:'th'
      },
      {
        label: 'Korean',
        value: 'ko',
        region:'sg'
      },
      {
        label: 'Japanese',
        value: 'ja',
        region:'jp'
      }
    ],
    regionNumberOptions: [
      {
        label: 'VN(Vietnam)',
        value: 4,
      },
      {
        label: 'MY(Malaysia)',
        value: 3,
      },
      {
        label: 'ID(Indonesia)',
        value: 2,
      },
      {
        label: 'SG(Singapore)',
        value: 1,
      },
      {
        label: 'TH(Thailand)',
        value: 5,
      },
      {
        label: 'KR(South Korea)',
        value: 6,
      },
      {
        label: 'JP(Japan)',
        value: 7,
      },
    ],
    regionPriceOptions: [
      {
        label: 'VND',
        value: 'vn',
      },
      {
        label: 'MYR',
        value: 'my',
      },
      {
        label: 'IDR',
        value: 'id',
      },
      {
        label: 'THB',
        value: 'th',
      },
      {
        label: 'SGD',
        value: 'sg',
      },
      {
        label: 'JPY',
        value: 'jp',
      },
    ],
    imageTypeOptions: [
      {
        label: 'Header image',
        value: 'header_image',
      },
      {
        label: 'How it works',
        value: 'how_it_work',
      },
      {
        label: 'Banner image',
        value: 'banner_image',
      },
    ],
    imageTypeMultipleSelectOptions: [
      {
        label: 'Header image',
        value: 'header_image',
      },
      {
        label: 'How it works',
        value: 'how_it_work',
      },
    ],
    answerQuestionOptions: [
      {
        label: 'No',
        value: 'no',
      },
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: "Don't know",
        value: 'dontknow',
      },
    ],
    weekDaysOption: [
      {
        label: 'Monday',
        value: 'monday',
      },
      {
        label: 'Tuesday',
        value: 'tuesday',
      },
      {
        label: 'Wednesday',
        value: 'wednesday',
      },
      {
        label: 'Thursday',
        value: 'thursday',
      },
      {
        label: 'Friday',
        value: 'friday',
      },
      {
        label: 'Saturday',
        value: 'saturday',
      },
      {
        label: 'Sunday',
        value: 'sunday',
      },
    ],
    statusOptions: [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ],
    productConfigTypes: [
      {
        label: 'High touch',
        value: 'high_touch',
      },
      {
        label: 'Low touch',
        value: 'low_touch',
      },
      {
        label: 'Public low touch',
        value: 'public',
      },
    ],
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    imageTypeMultipleSelectOptions: state => state.imageTypeMultipleSelectOptions,
    statusOptions: state => state.statusOptions,
    offeringTypes: state => state.offeringTypes,
    regionNumberOptions: state => state.regionNumberOptions,
    productConfigTypes: state => state.productConfigTypes,
    weekDaysOption: state => state.weekDaysOption,
    answerQuestionOptions: state => state.answerQuestionOptions,
    languageOptions: state => state.languageOptions,
    regionOptions: (state, getters, rootState, rootGetters) => state.regionOptions.filter(x => rootGetters['authenticate/regionsAcept'].includes(x.value)),
    // regionOptions: (state, getters, rootState, rootGetters) => state.regionOptions.filter(x => rootGetters['authenticate/regionsAcept'].includes(x.value)),
    regionPriceOptions: state => state.regionPriceOptions,
    imageTypeOptions: state => state.imageTypeOptions,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    fetchRegionConfig(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/region-config/get-by-current-region')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNotify(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/notification', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTrackings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tracking', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
