import Cookie from 'js-cookie'
// import axios from 'axios'
import {
  useAcl,
} from 'vue-simple-acl'
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import {
  authService,
} from '@/auth'
import * as config from '../../utils/config'

export default {
  namespaced: true,
  state: {
    mailchimpAccount: [],
    mailAction: null,
  },
  getters: {
    mailchimpAccount(state) {
      return state.mailchimpAccount != null
    },
    mailAction(state) {
      return state.mailAction
    },
  },
  mutations: {

    setMailAction(state, mailAction) {
      state.mailAction = mailAction
    },
    setMailchimpAccount(state, mailchimpAccount) {
      state.mailchimpAccount = mailchimpAccount
    },
  },
  actions: {

    // MAILCHIMP ACCOUNT
    fetchAccountMailchimp(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/mailchimp/mailchimp-account')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createAccountMailchimp(ctx, account) {
      return new Promise((resolve, reject) => {
        axios
          .post('/mailchimp/mailchimp-account', account)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMailchimpAccount(ctx, {
      accountID,
      account,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/mailchimp/mailchimp-account/${accountID}`, account)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMailChimpTemplates(ctx, query) {
      return new Promise((resolve, reject) => {
        axios
          .get('/mailchimp/mailchimp-template', {
            params: query,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    previewTemplate(ctx, templateId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/mailchimp/mailchimp-template/${templateId}/preview`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMailActions(ctx, query) {
      return new Promise((resolve, reject) => {
        axios
          .get('/mailchimp/mail-action', {
            params: query,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    excuteMailActions(ctx, action) {
      return new Promise((resolve, reject) => {
        axios
          .post('/mailchimp/mail-action/test-mail', action)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    assignMailActionsTemplate(ctx, {
      template_id,
      action_id,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/mailchimp/mail-action/${action_id}/template/${template_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMailActionsTemplate(ctx, {
      action_id,
      action,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/mailchimp/mail-action/${action_id}`,action)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    syncMailchimpAccount(ctx, account_id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/mailchimp/mailchimp-account/${account_id}/sync`, {

          }, {
            timeout: 30000,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
