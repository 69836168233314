import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import store from './store'
import { USER } from './utils/endpoints'
/**
 * Config for the oidc client.
 */
const settings = {
  // Where the tokens will be stored
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  // URL to the authentication server (including realm)
  authority: `${process.env.VUE_APP_SSO_ENDPOINT}/openam/oauth2/authorize`,
  // The name of the client in Keycloak setup for this service
  client_id: process.env.VUE_APP_SSO_CLIENT_ID,
  // Where to redirect the user to after successful authentication
  redirect_uri: `${process.env.VUE_APP_APP_URL}`,
  // Where to redirect the user to after logging the user out
  post_logout_redirect_uri:
    `${process.env.VUE_APP_APP_URL}`,
  // Indicate the the authorization code flow should be used
  response_type: 'code',
  // "openid" tells the server that this client uses oidc for authentication
  scope: 'openid email cloudEmail profile country role cn organization uid phone',
  // Enable automatic (silent) renewal of the access token

  automaticSilentRenew: false,
  loadUserInfo: true,
  metadata: {
    post_logout_redirect_uri:
      `${process.env.VUE_APP_APP_URL}`,
    token_endpoint: USER.AUTH_SSO_LOGIN,
    authorization_endpoint: `${process.env.VUE_APP_SSO_ENDPOINT}/openam/oauth2/authorize`,
    userinfo_endpoint: USER.AUTH_ME,
    issuer: `${process.env.VUE_APP_SSO_ENDPOINT}:443/openam/oauth2`,
    end_session_endpoint: `${process.env.VUE_APP_SSO_ENDPOINT}/openam/oauth2/connect/endSession`,
  },
  fetchRequestCredentials:"include",
  response_mode: 'query'
}

const userManager = new UserManager(settings)

/**
 * Class to encapsulate all authentication related logic.có
 */
class AuthService {
  /**
   * Initate the login process.
   */
  login() {
    userManager
      .signinRedirect()
      .then(() => {
        store.dispatch('authenticate/authenticateUserSso')
      })
      .catch(error => {
        console.error('ERROR:::', error);
        throw new Error(error)
      })
  }

  logout(
  ) {
    userManager.clearStaleState()
    userManager.removeUser()
    // userManager.signoutRedirect()
  }

  /**
   * Handles the redirect from the OAuth server after a user logged in.
   */
  handleLoginRedirect() {
    // Returns a promise
    return new Promise((resolve) => {
      userManager.signinRedirectCallback();
      resolve(true);
    });
  }

  /**
   * Handles the redirect from the OAuth server after a user logged out.
   */
  handleLogoutRedirect() {
    return userManager.signoutRedirectCallback()
  }

  /**
   * Checks whether or not a user is currently logged in.
   *
   * Returns a promise which will be resolved to true/false or be rejected with an error.
   */
  isUserLoggedIn() {
    return new Promise((resolve, reject) => {
      userManager
        .getUser()
        .then(user => {
          if (user === null) {
            resolve(false)
          }
          resolve(true)
        })
        .catch(error => reject(false))
    })
  }

  /**
   * Get the profile data for the currently authenticated user.
   *
   * Returns an empty object if no user is logged in.
   */
  getUser() {
    return new Promise((resolve, reject) => {
      userManager
        .getUser()
        .then(user => {
          if (user === null) {
            resolve(null)
          }
          resolve(user)
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get the profile data for the currently authenticated user.
   *
   * Returns an empty object if no user is logged in.
   */
  getProfile() {
    return new Promise((resolve, reject) => {
      userManager
        .getUser()
        .then(user => {
          if (user === null) {
            resolve(null)
          }
          resolve(user)
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get the access token.
   *
   * Can be used to make requests to the backend.
   */
  getAccessToken() {
    return new Promise((resolve, reject) => {
      userManager
        .getUser()
        .then(user => {
          resolve(user.access_token)
        })
        .catch(error => reject(error))
    })
  }
}

/**
 * Create and expose an instance of the auth service.
 */
export const authService = new AuthService()

/**
 * Default export to register the authentication service in the global Vue instance.
 *
 * This allows us to reference it using "this.$auth" whenever we are inside of a Vue context.
 */
export default {
  install(Vue) {
    Vue.prototype.$auth = authService
  },
}
