// eslint-disable-next-line import/no-cycle
import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBanners(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/media', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBanner(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/banner/banners/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBanner(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/banner/banners', { banner: productData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
