/* eslint-disable import/no-cycle */
import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/middleware/auth'
import checkLogin from '@/middleware/check-login'
import checkSSO from '@/middleware/check-sso'
import checkSso2 from '@/middleware/check-sso-2'
import * as config from '../utils/config'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    // GENERAL routes
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/index.vue'),
      meta: {
        pageTitle: 'Home',
        middleware: sessionStorage.getItem(config.IS_LOGIN_SSO) ? null : [auth],
      },
    },
    {
      path: '/report-page',
      name: 'report-page',
      component: () => import('@/views/report/index.vue'),
      meta: {
        pageTitle: 'Report dashboard',
        middleware: [auth],
        breadcrumb: [{
          text: 'Report dashboard',
          active: true,
        }],
      },
    },
    // USER SELF ROUTES
    {
      path: '/user-profile',
      name: 'user-profile',
      component: () => import('@/views/userManager/profile/index.vue'),
      meta: {
        pageTitle: 'User profile',
        middleware: [auth],
        breadcrumb: [{
          text: 'User profile',
          active: true,
        }],
      },
    },
    // USER MANAGE ROUTER
    {
      path: '/user-manager',
      name: 'user-manager',
      component: () => import('@/views/userManager/index.vue'),
      meta: {
        can: 'ur_read',
        pageTitle: 'User manager dashboard',
        middleware: [auth],
        breadcrumb: [{
          text: 'User manager',
          active: true,
        }],
      },
    },
    {
      path: '/user-manager/detail/:id',
      name: 'user-view',
      component: () => import('@/views/userManager/detail/index.vue'),
      meta: {
        can: 'ur_read',
        pageTitle: 'User detail',
        middleware: [auth],
        breadcrumb: [{
          text: 'User detail',
          active: true,
        }],
      },
    },
    {
      path: '/user-manager/edit/:id',
      name: 'user-edit',
      component: () => import('@/views/userManager/edit/index.vue'),
      meta: {
        can: 'ur_update',
        pageTitle: 'Edit user',
        middleware: [auth],
        breadcrumb: [{
          text: 'Edit user',
          active: true,
        }],
      },
    },
    // Rbac
    {
      path: '/user-manager/rbac',
      name: 'rbac',
      component: () => import('@/views/rbac/index.vue'),
      meta: {
        pageTitle: 'Permission controller',
        middleware: [auth],
        breadcrumb: [{
          text: 'Permission controller',
          active: true,
        }],
      },
    },
    {
      path: '/user-manager/rbac/edit/:id',
      name: 'rbac-edit',
      component: () => import('@/views/rbac/editRbac/index.vue'),
      meta: {
        pageTitle: 'Permission controller edit',
        middleware: [auth],
        breadcrumb: [{
          text: 'Permission controller edit',
          active: true,
        }],
      },
    },
    {
      path: '/user-manager/rbac/add',
      name: 'rbac-add',
      component: () => import('@/views/rbac/addRbac/index.vue'),
      meta: {
        pageTitle: 'Add permission controller',
        middleware: [auth],
        breadcrumb: [{
          text: 'Add permission controller',
          active: true,
        }],
      },
    },
    // SALE CAMPAIGN ROUTES
    {
      path: '/sale-campaign',
      name: 'sale-campaign',
      component: () => import('@/views/saleCampaign/index.vue'),
      meta: {
        pageTitle: 'Sale campaign',
        middleware: [auth],
        breadcrumb: [{
          text: 'Sale campaign',
          active: true,
        }],
      },
    },
    {
      path: '/sale-campaign/detail-campaign/:id',
      name: 'detail-campaign',
      component: () => import('@/views/saleCampaign/detail/index.vue'),
      meta: {
        pageTitle: 'Detail campaign',
        middleware: [auth],
        breadcrumb: [{
          text: 'Detail campaign',
          active: true,
        }],
      },
    },
    {
      path: '/sale-campaign/add-sale-campaign',
      name: 'add-sale-campaign',
      component: () => import('@/views/saleCampaign/addSale/index.vue'),
      meta: {
        pageTitle: 'Add sale campaign',
        middleware: [auth],
        breadcrumb: [{
          text: 'Sale campaign',
          active: true,
        }],
      },
    },
    {
      path: '/sale-manager/coupon',
      name: 'coupon-manager',
      component: () => import('@/views/couponManager/index.vue'),
      meta: {
        pageTitle: 'Coupon manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Coupon manager',
          active: true,
        }],
      },
    },
    // ASSET MANAGE ROUTES
    {
      path: '/asset-manager',
      name: 'asset-manager',
      component: () => import('@/views/offeringManager/productManager/index.vue'),
      meta: {
        pageTitle: 'Asset manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Asset manager',
          active: true,
        }],
      },
    },
    {
      path: '/asset-manager/add-asset',
      name: 'add-asset',
      component: () => import('@/views/offeringManager/productManager/addProduct/index.vue'),
      meta: {
        pageTitle: 'Add asset',
        middleware: [auth],
        breadcrumb: [{
          text: 'Add asset',
          active: true,
        }],
      },
    },
    {
      path: '/asset-manager/detail-asset/:id',
      name: 'detail-asset',
      component: () => import('@/views/offeringManager/productManager/detailProduct/index.vue'),
      meta: {
        pageTitle: 'Detail asset',
        middleware: [auth],
        breadcrumb: [{
          text: 'Detail asset',
          active: true,
        }],
      },
    },
    {
      path: '/asset-manager/duplicate-asset/:id',
      name: 'duplicate-asset',
      component: () => import('@/views/offeringManager/productManager/duplicateProduct/index.vue'),
      meta: {
        pageTitle: 'Duplicate asset',
        middleware: [auth],
        breadcrumb: [{
          text: 'Duplicate asset',
          active: true,
        }],
      },
    },
    {
      path: '/asset-manager/onedit-asset/:id',
      name: 'onedit-asset',
      component: () => import('@/views/offeringManager/productManager/onEditProduct/index.vue'),
      meta: {
        pageTitle: 'On edit asset',
        middleware: [auth],
        breadcrumb: [{
          text: 'On edit asset',
          active: true,
        }],
      },
    },
    {
      path: '/asset-manager/asset-preview/:slug/preview/:region',
      name: 'asset-preview',
      component: () => import('@/views/offeringPreview/productPreview/index.vue'),
      meta: {
        pageTitle: 'Asset preview',
        middleware: [auth],
        layout: 'full',
      },
    },

    // ELEARN MANAGE ROUTES
    {
      path: '/elearn-manager',
      name: 'elearn-manager',
      component: () => import('@/views/offeringManager/elearnManager/index.vue'),
      meta: {
        pageTitle: 'E-learnings manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'E-learnings manager',
          active: true,
        }],
      },
    },
    {
      path: '/elearn-manager/add-elearn',
      name: 'add-elearn',
      component: () => import('@/views/offeringManager/elearnManager/addProduct/index.vue'),
      meta: {
        pageTitle: 'Add E-learnings',
        middleware: [auth],
        breadcrumb: [{
          text: 'Add E-learnings',
          active: true,
        }],
      },
    },
    {
      path: '/elearn-manager/detail-elearn/:id',
      name: 'detail-elearn',
      component: () => import('@/views/offeringManager/elearnManager/detailProduct/index.vue'),
      meta: {
        pageTitle: 'Detail E-learning',
        middleware: [auth],
        breadcrumb: [{
          text: 'Detail E-learning',
          active: true,
        }],
      },
    },
    {
      path: '/elearn-manager/duplicate-elearn/:id',
      name: 'duplicate-elearn',
      component: () => import('@/views/offeringManager/elearnManager/duplicateProduct/index.vue'),
      meta: {
        pageTitle: 'Duplicate E-learning',
        middleware: [auth],
        breadcrumb: [{
          text: 'Duplicate E-learning',
          active: true,
        }],
      },
    },
    {
      path: '/elearn-manager/onedit-elearn/:id',
      name: 'onedit-elearn',
      component: () => import('@/views/offeringManager/elearnManager/onEditProduct/index.vue'),
      meta: {
        pageTitle: 'On edit E-learning',
        middleware: [auth],
        breadcrumb: [{
          text: 'On edit E-learning',
          active: true,
        }],
      },
    },
    {
      path: '/elearn-manager/elearn-preview/:slug/preview/:region',
      name: 'elearn-preview',
      component: () => import('@/views/offeringPreview/elearnPreview/index.vue'),
      meta: {
        pageTitle: 'E-learning preview',
        middleware: [auth],
        layout: 'full',
      },
    },

    // EVENT MANAGE ROUTES
    {
      path: '/event-manager',
      name: 'event-manager',
      component: () => import('@/views/offeringManager/eventManager/index.vue'),
      meta: {
        pageTitle: 'Event manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Event manager',
          active: true,
        }],
      },
    },
    {
      path: '/event-manager/add-event',
      name: 'add-event',
      component: () => import('@/views/offeringManager/eventManager/addProduct/index.vue'),
      meta: {
        pageTitle: 'Add event',
        middleware: [auth],
        breadcrumb: [{
          text: 'Add event',
          active: true,
        }],
      },
    },
    {
      path: '/event-manager/detail-event/:id',
      name: 'detail-event',
      component: () => import('@/views/offeringManager/eventManager/detailProduct/index.vue'),
      meta: {
        pageTitle: 'Detail event',
        middleware: [auth],
        breadcrumb: [{
          text: 'Detail event',
          active: true,
        }],
      },
    },
    {
      path: '/event-manager/duplicate-event/:id',
      name: 'duplicate-event',
      component: () => import('@/views/offeringManager/eventManager/duplicateProduct/index.vue'),
      meta: {
        pageTitle: 'Duplicate event',
        middleware: [auth],
        breadcrumb: [{
          text: 'Duplicate event',
          active: true,
        }],
      },
    },
    {
      path: '/event-manager/onedit-event/:id',
      name: 'onedit-event',
      component: () => import('@/views/offeringManager/eventManager/onEditProduct/index.vue'),
      meta: {
        pageTitle: 'On edit event',
        middleware: [auth],
        breadcrumb: [{
          text: 'On edit event',
          active: true,
        }],
      },
    },
    {
      path: '/event-manager/event-preview/:slug/preview/:region',
      name: 'elearn-preview',
      component: () => import('@/views/offeringPreview/eventPreview/index.vue'),
      meta: {
        pageTitle: 'Event preview',
        middleware: [auth],
        layout: 'full',
      },
    },

    // PUBLICATION MANAGE ROUTES
    {
      path: '/publication-manager',
      name: 'publication-manager',
      component: () => import('@/views/offeringManager/publicationManager/index.vue'),
      meta: {
        pageTitle: 'Publication manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Publication manager',
          active: true,
        }],
      },
    },
    {
      path: '/publication-manager/add-publication',
      name: 'add-publication',
      component: () => import('@/views/offeringManager/publicationManager/addProduct/index.vue'),
      meta: {
        pageTitle: 'Add publication',
        middleware: [auth],
        breadcrumb: [{
          text: 'Add publication',
          active: true,
        }],
      },
    },
    {
      path: '/publication-manager/detail-publication/:id',
      name: 'detail-publication',
      component: () => import('@/views/offeringManager/publicationManager/detailProduct/index.vue'),
      meta: {
        pageTitle: 'Detail publication',
        middleware: [auth],
        breadcrumb: [{
          text: 'Detail publication',
          active: true,
        }],
      },
    },
    {
      path: '/publication-manager/duplicate-publication/:id',
      name: 'duplicate-publication',
      component: () => import('@/views/offeringManager/publicationManager/duplicateProduct/index.vue'),
      meta: {
        pageTitle: 'Duplicate publication',
        middleware: [auth],
        breadcrumb: [{
          text: 'Duplicate publication',
          active: true,
        }],
      },
    },
    {
      path: '/publication-manager/onedit-publication/:id',
      name: 'onedit-publication',
      component: () => import('@/views/offeringManager/publicationManager/onEditProduct/index.vue'),
      meta: {
        pageTitle: 'On edit publication',
        middleware: [auth],
        breadcrumb: [{
          text: 'On edit publication',
          active: true,
        }],
      },
    },
    {
      path: '/publication-manager/publication-preview/:slug/preview/:region',
      name: 'publication-preview',
      component: () => import('@/views/offeringPreview/publicationPreview/index.vue'),
      meta: {
        pageTitle: 'Publication preview',
        middleware: [auth],
        layout: 'full',
      },
    },

    {
      path: '/asset-manager/category-manager',
      name: 'category-manager',
      component: () => import('@/views/categoryManager/index.vue'),
      meta: {
        pageTitle: 'Category manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Category manager',
          active: true,
        }],
      },
    },
    {
      path: '/asset-manager/tag-manager',
      name: 'tag-manager',
      component: () => import('@/views/tagManager/index.vue'),
      meta: {
        pageTitle: 'Tag manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Tag manager',
          active: true,
        }],
      },
    },
    {
      path: '/asset-manager/bussiness-manager',
      name: 'bussiness-manager',
      component: () => import('@/views/bussinessManager/index.vue'),
      meta: {
        pageTitle: 'Bussiness challenge',
        middleware: [auth],
        breadcrumb: [{
          text: 'Bussiness challenge',
          active: true,
        }],
      },
    },
    {
      path: '/asset-manager/role-manager',
      name: 'role-manager',
      component: () => import('@/views/roleManager/index.vue'),
      meta: {
        pageTitle: 'Role manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Role manager',
          active: true,
        }],
      },
    },
    // REGION MODULE
    {
      path: '/region-config',
      name: 'region-config',
      component: () => import('@/views/regionConfig/index.vue'),
      meta: {
        pageTitle: 'Region config',
        middleware: [auth],
        breadcrumb: [{
          text: 'Region config',
          active: true,
        }],
      },
    },
    // MEDIA MODULE
    {
      path: '/media-manager',
      name: 'media-manager',
      component: () => import('@/views/mediaManager/index.vue'),
      meta: {
        pageTitle: 'Media manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Media manager',
          active: true,
        }],
      },
    },
    {
      path: '/media-manager/detail-banner/:id',
      name: 'detail-banner',
      component: () => import('@/views/mediaManager/detail/index.vue'),
      meta: {
        pageTitle: 'Detail banner',
        middleware: [auth],
        breadcrumb: [{
          text: 'Detail banner',
          active: true,
        }],
      },
    },

    // MAILCHIMP MODULE

    {
      path: '/mailchimp-manager/account',
      name: 'mailchimp-manager',
      component: () => import('@/views/mailchimp/account/index.vue'),
      meta: {
        pageTitle: 'Mailchimp account',
        middleware: [auth],
        breadcrumb: [{
          text: 'Mailchimp manager',
          active: true,
        }],
      },
    },
    {
      path: '/mailchimp-manager/mail-action',
      name: 'mail-action',
      component: () => import('@/views/mailchimp/action/index.vue'),
      meta: {
        pageTitle: 'Mail action',
        middleware: [auth],
        breadcrumb: [{
          text: 'Mailchimp manager',
          active: true,
        }],
      },
    },
    {
      path: '/mailchimp-manager/templates',
      name: 'mailchimp-templates',
      component: () => import('@/views/mailchimp/templates/index.vue'),
      meta: {
        pageTitle: 'Mailchimp templates',
        middleware: [auth],
        breadcrumb: [{
          text: 'Mailchimp manager',
          active: true,
        }],
      },
    },

    // ODER MODULE

    {
      path: '/order-manager',
      name: 'order-manager',
      component: () => import('@/views/oderManager/index.vue'),
      meta: {
        pageTitle: 'Order manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Order manager',
          active: true,
        },
        ],
      },
    },
    {
      path: '/asset-manager/order-detail/:id',
      name: 'order-detail',
      component: () => import('@/views/oderManager/detailOrder/index.vue'),
      meta: {
        pageTitle: 'Order detail',
        middleware: [auth],
        breadcrumb: [{
          text: 'Order detail',
          active: true,
        }],
      },
    },
    // Payment module

    {
      path: '/payment-section',
      name: 'payment-section',
      component: () => import('@/views/paymentSection/index.vue'),
      meta: {
        pageTitle: 'Payment section',
        middleware: [auth],
        breadcrumb: [{
          text: 'Payment section',
          active: true,
        },
        ],
      },
    },
    // THIRD PARTY CONFIG
    {
      path: '/third-party-config',
      name: 'third-party-config',
      component: () => import('@/views/thirdPartyConfig/index.vue'),
      meta: {
        pageTitle: 'Third party config',
        middleware: [auth],
        breadcrumb: [{
          text: 'Third party config',
          active: true,
        },
        ],
      },
    },

    // TRACKING
    {
      path: '/tracking',
      name: 'tracking',
      component: () => import('@/views/tracking/index.vue'),
      meta: {
        pageTitle: 'Tracking',
        middleware: [auth],
        breadcrumb: [{
          text: 'Tracking',
          active: true,
        },
        ],
      },
    },
    // NOTIFICATION
    {
      path: '/notification',
      name: 'notification',
      component: () => import('@/views/notification/index.vue'),
      meta: {
        pageTitle: 'Notification',
        middleware: [auth],
        breadcrumb: [{
          text: 'Notification',
          active: true,
        },
        ],
      },
    },

    // NOT AUTHORIZED
    {
      path: '/sso',
      name: 'login',
      component: () => import('@/views/login/index.vue'),
      meta: {
        middleware: [checkLogin],
        layout: 'full',
      },
    },

    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/register/index.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

    // ON TESTING
    {
      path: '/login-v2',
      name: 'login-v2',
      component: () => import('@/views/login/login-v2.vue'),
      meta: {
        middleware: [checkSSO, checkLogin],
        layout: 'full',
      },
    },
    // MEDIA MODULE
    {
      path: '/spotlight-manager',
      name: 'spotlight-manager',
      component: () => import('@/views/offeringManager/spotLightManager/index.vue'),
      meta: {
        pageTitle: 'Spotlight manager',
        middleware: [auth],
        breadcrumb: [{
          text: 'Spotlight manager',
          active: true,
        }],
      },
    },
    {
      path: '/spotlight-manager/detail/:id',
      name: 'detail-spotlight',
      component: () => import('@/views/offeringManager/spotLightManager/detail/index.vue'),
      meta: {
        pageTitle: 'Detail spotlight',
        middleware: [auth],
        breadcrumb: [{
          text: 'Detail spotlight',
          active: true,
        }],
      },
    },
    //top bar
    {
      path: '/top-bar',
      name: 'top-bar',
      component: () => import('@/views/topBar/index.vue'),
      meta: {
        pageTitle: 'Top bar',
        middleware: [auth],
        breadcrumb: [{
          text: 'Top bar',
          active: true,
        }],
      },
    },
    {
      path: '/top-bar/detail/:id',
      name: 'detail-top-bar',
      component: () => import('@/views/topBar/detail/index.vue'),
      meta: {
        pageTitle: 'Detail top bar',
        middleware: [auth],
        breadcrumb: [{
          text: 'Detail top bar',
          active: true,
        }],
      },
    },
  ],
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next
  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({
      ...context,
      next: nextMiddleware,
    })
  }
}
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]
    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({
      ...context,
      next: nextMiddleware,
    })
  }
  return next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
