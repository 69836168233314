/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    productRole: [],
  },
  getters: {
    productRole(state) {
      return state.productRole
    },
  },
  mutations: {
    setRole(state, productRole) {
      state.productRole = productRole.map(x => ({
        label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
        value: x.id,
      }))
    },
  },
  actions: {
    fetchSpotlight(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/spotlights')
          .then(response => {
            ctx.commit('setRole', response.body)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchSpotlightList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/spotlights', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addSpotlight(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/spotlights', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSpotlight(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/spotlights/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSpotlight(ctx, { spotlight }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/spotlights`, spotlight)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailSpotlight(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/spotlights/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updloadMedia(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/spotlights/upload',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
