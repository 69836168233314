/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    productRole: [],
  },
  getters: {
    productRole(state) {
      return state.productRole
    },
  },
  mutations: {
    setRole(state, productRole) {
      state.productRole = productRole.map(x => ({
        label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
        value: x.id,
      }))
    },
  },
  actions: {
    fetchRole(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/product-roles', {
            params: queryParams || null,
          })
          .then(response => {
            ctx.commit('setRole', response.body)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchRoleList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/product-roles', {
            params: queryParams,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product-roles', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/product-roles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRole(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/product-roles/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
