// eslint-disable-next-line import/no-cycle
import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRegionConfig(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/region-config', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRegionConfig(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/region-config/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRegionConfig(ctx, { id, regionConfig }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/region-config/${id}`, regionConfig)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBanner(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/banner/banners', { banner: productData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
