import Vue from 'vue'
// axios
import axios from 'axios'
import store from '@/store'
import * as enpointConfig from '../utils/endpoints'
import router from '@/router'
// import router from '@/router'

const axiosIns = axios.create({
  baseURL: enpointConfig.BASE_URL,
  withCredentials: true,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
})
axiosIns.interceptors.response.use(
  response =>
  // if (response.data.success) {
    Promise.resolve({
      body: response.data,
      request: response,
    }), // } else throw new APIError(response)

  error => {
    if (error.response && error.response.status && [401, 403].includes(error.response.status)) {
      // logout
      store.dispatch('authenticate/logout')
      if(router.currentRoute.name != 'login') {
        router.push({ name: 'login' })
      }
    }
    return Promise.reject(error)
  },
)

axiosIns.interceptors.request.use(config => {
    config.headers = {
      // Authorization: `Bearer ${store.state.authenticate.token}`,
      'x-admin-region': localStorage.regionAccept,
      'x-cms-site': true,
    }
  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
