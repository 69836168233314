/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import * as enpointConfig from '@/utils/endpoints'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVendor(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${enpointConfig.VENDOR.GET_LIST_VENDORS}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
